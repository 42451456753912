<template>
  <div class="row mt-2 p-2 myboder1">
    <div class="col-12 m-0 p-0 d-flex justify-content-center">
      <img   src="/images/upload.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxUploadedFileNew",
  //   props: ["tital", "status"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.tital {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
.myboder1 {
  border: 1px dashed #22b7a0;
  border-radius: 8px;
}
 
</style>