<template>
  <div class="row">
    <div class="col-12 p-0">
      <!-- {{ item }} -->
      <table class="table table--default bgth">
        <thead class="">
          <tr>
            <th class="text-left col-6">STEP</th>
            <th class="text-left col-4">RESPONSIBLE</th>
            <th class="text-left col-2">STATUS</th>
          </tr>
        </thead>
        <!-- {{item.data}} -->
        <tbody v-for="(e, index) in item.data" :key="index">
          <!-- {{e.job_stage_node_id}} -->
          <tr
            :class="e.job_stage_node_id == jobStageNodeId ? 'select' : ''"
            @click="
              sendjobStageNodeId(e.job_stage_node_id),
                sendcan_action(e.can_action)
            "
          >
            <td class="text-left">
              <TableJobStep
                :img="img"
                :tital="e.text"
                :subtital="e.updated_at"
              />
            </td>
            <td class="text-left">
              <TableJobRespon
                :tital="e.group_stage_name"
                :subtital="e.created_by"
                :department="e.department"
              />
            </td>
            <td class="text-left">
              <WaitActive
                class=""
                :text="e.status"
                :disable="
                  e.can_action == 'Y' ? 'N' : e.status == 'active' ? 'N' : 'Y'
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- {{ item }} -->
  </div>
</template>

<script>
import TableJobStep from "@/components/JobDetail/TableJobStep.vue";
import TableJobRespon from "@/components/JobDetail/TableJobRespon.vue";
import WaitActive from "@/components/Common/WaitActive.vue";
export default {
  name: "TableJob",
  props: ["item", "img", "jobStageNodeId"],
  components: { TableJobStep, TableJobRespon, WaitActive },
  methods: {
    sendjobStageNodeId(value) {
      // console.log(value);
      let mydata = [];
      mydata.push(value);
      this.$emit("sendjobStageNodeId", mydata[0]);
    },
    sendcan_action(value) {
      this.$emit("sendcan_action", value);
    },
  },
};
</script>

<style scpoed>
.bgth th {
  font-size: 0.75em;
  font-weight: 600;
  background-color: #64748b;
  color: #f8fafc;
}
table {
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e2e8f0;
  table-layout: fixed;
}
.select {
  background-color: aquamarine;
}
</style>