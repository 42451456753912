<template>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0">
      <p class="tital m-0 p-0">{{ tital }}</p>
      <p class="subtital m-0 p-0">{{ subtital }}</p>
      <p class="subtital m-0 p-0">{{ department }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableJobStep",
  props: ["tital", "subtital", "department"],
};
</script>

<style scoped>
 
.tital {
  color: #0f172a;
  font-weight: 600;
  font-size: 0.875em;
}
.subtital {
  color: #64748b;
  font-weight: 400;
  font-size: 0.75em;
}
</style>