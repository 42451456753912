<template>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0">
      <div class="row m-0 p-0">
        <div class="col m-0 p-0">
          <img :src="img" alt="" class="rounded-circle img" />
        </div>
        <div class="col-10 m-0 p-0">
          <p class="tital m-0 p-0">{{ tital }}</p>
          <p class="subtital m-0 p-0">{{ subtital }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableJobStep",
  props: ["img", "tital", "subtital"],
};
</script>

<style scoped>
.img {
  width: 32px;
  height: 32px;
}
.tital {
  color: #0f172a;
  font-weight: 600;
  font-size: 0.875em;
}
.subtital {
  color: #64748B;
  font-weight: 400;
  font-size: 0.75em;
}
</style>