<template>
  <div
    class="modal fade"
    id="BoxBoxUploadedFileNew"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row m-0 p-0">
            <!-- -------------- head ------------------ -->
            <div class="col-12">
              <p class="mb-5 titalmodal">Add new document</p>
              <p class="mb-5 subtitalmodal">เพิ่มเอกสารใหม่ของคุณ</p>
              <!-- {{ job_stage_node_id }} -->
            </div>
            <!-- ------------------------ body ---------------------- -->

            <div class="col-12">
              <form>
                <div class="row mb-2">
                  <div class="col-12 col-md-6">
                    <label for="Document">Document</label>
                    <select
                      v-model="docBoxData"
                      name="department"
                      class="js-example-basic-single js-states form-control"
                    >
                      <option disabled value="">Please select</option>
                      <option v-for="e in MainDocData" :key="e.id" :value="e">
                        {{ e.doc_name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="text">Count</label>
                    <input
                      v-model="docCount"
                      type="text"
                      class="form-control"
                      placeholder="Count"
                    />
                  </div>
                </div>
              </form>
            </div>

            <!-- ------- close-------------------- -->
            <div class="col-12 m-0 p-0 d-flex justify-content-end">
              <button
                type="button"
                class="btn close mx-2 px-5"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn comfirm mx-2 px-5"
                data-dismiss="modal"
                @click="Confirm()"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxBoxUploadedFileNew",
  props: ["job_stage_node_id"],
  data() {
    return {
      MainDocData: [],
      docBoxData: "",
      docCount: "",
    };
  },
  mounted() {
    this.cleardata();
    this.GetMainDoc();
  },
  methods: {
    cleardata() {
      this.docBoxData = "";
      this.docCount = "";
    },
    async GetMainDoc() {
      try {
        let data = {};
        let getAPI = await this.$API.call_API(
          "get",
          "workflow/DocumentTemplates",
          data,
          "auth"
        );
        //console.log("GetMainDoc:");
       // console.log(getAPI);
        this.MainDocData = getAPI.data.data;
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },

    async Confirm() {
      try {
        // console.log(this.docBoxData);
        // console.log(this.docCount);
        if (this.docBoxData == "" || this.docCount == "") {
          throw "กรุณากรอกข้อมูลเอกสาให้ครบถ้วน";
        }
        let data = {
          jobStageNodeId: this.job_stage_node_id,
          docName: this.docBoxData.doc_name,
          docPrefix: this.docBoxData.prefix,
          docCount: this.docCount,
          docType: this.docBoxData.doc_type,
        };
        //console.log(data);
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/AddMainDocumentStages",
          data,
          "auth"
        );
       this.$emit("sendjobStageNodeId", this.job_stage_node_id);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
  },
};
</script>

<style scoped>
.titalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 2.25em;
}
.subtitalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 1.125em;
}
.close {
  color: #475569;
  font-weight: 600;
  font-size: 0.75em;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
}
.comfirm {
  color: #f8fafc;
  font-weight: 600;
  font-size: 0.75em;
  background: #20c997;
  border: 1px solid #20c997;
  border-radius: 5px;
}
</style>