<template>
  <div class="row m-0 p-0 bd">
    <!-- ------------------------------------- -->
    <div class="col-9 m-0 p-0">
      <div class="row m-0 p-0">
        <!-- ---------------------------- -->
        <div class="row m-0 p-0 w-100">
          <div class="col m-0 p-0">
            <img
              class="img"
              :src="mydata.img"
              onerror="this.onerror=null;this.src='/images/ecotech.png';"
              alt=""
            />
          </div>

          <div class="col-10 m-0 pl-2">
            <p class="tital m-0 p-0">{{ mydata.tital }}</p>
            <p class="size m-0 p-0">{{ mydata.size }}</p>
          </div>
        </div>

        <!-- ---------------------------- -->
      </div>
      <p class="m-0 p-0 date">{{ mydata.date }}</p>
    </div>
    <!-- ------------------------------------- -->
    <div class="col m-0 p-0">
      <!-- <span v-html="eye"></span>&nbsp; <span v-html="print"></span>&nbsp;
      <span v-html="load"></span> -->

      <div class="row m-0 p-0">
        <div
          class="col btn m-0 p-0"
          v-if="mydata.typeimg == 1"
          v-html="eye"
          @click="callModalImg()"
        ></div>
        <!-- ------------------ -->
        <div class="col btn m-0 p-0" v-if="mydata.typeimg == 0"></div>
        <!-- ------------------ -->
        <div class="col btn m-0 p-0" v-html="print" @click="printdata()"></div>
        <!-- ------------------ -->

        <div class="col btn m-0 p-0" v-html="load" @click="download()"></div>
        <!-- ------------------ -->
      </div>
    </div>
    <!-- <div class="col-12 bd"></div> -->
    <ModalImg :key="modal" :dataimg="mydata.img" :modalId="mydata.id" />
  </div>
</template>

<script>
import axios from "axios";
import ModalImg from "@/components/JobDetail/ModalImg.vue";
import printJS from "print-js";
export default {
  name: "BoxProjectFile",
  props: [
    "mydata",
    // "img",
    // "tital",
    // "size",
    // "date",
    // "typeimg",
    // "myid",
    // "can_action",
    // "synology_path",
  ],
  components: { ModalImg },
  data() {
    return {
      modal: "000",
      eye: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="15.83" fill="#09244B" class="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
</svg>`,
      print: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="15.83" fill="#09244B" class="bi bi-printer" viewBox="0 0 16 16">
  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
</svg>`,
      load: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="15.83" fill="#09244B" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
</svg>`,
    };
  },
  mounted() {
    //console.log(this.mydata);
  },
  methods: {
    printdata() {
      try {
        let type = "";
        if (
          String(this.mydata.type).toLowerCase() == "png" ||
          String(this.mydata.type).toLowerCase() == "gif" ||
          String(this.mydata.type).toLowerCase() == "jpg" ||
          String(this.mydata.type).toLowerCase() == "jpeg"
        ) {
          type = "image";
        } else if (String(this.mydata.type).toLowerCase() == "pdf") {
          type = "pdf";
        }
        else{
          throw 'ไม่สามารถปลิ้นเอกสารที่ไม่อยู่ในรูปเเบบของ รูปภาพ เเละ ไฟล์ PDF'
        }

        setTimeout(() => {
          printJS({
            printable: this.mydata.synology_path,
            type: type,
            // header: "My cool image header",
          });
        }, 500);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    callModalImg() {
      this.modal = Date.now();

      $(`#${this.mydata.id}`).modal("show");
      // try {

      //   if (this.can_action == 0) {
      //     throw "ไม่สามารถเข้าถึงสิทธิ์เอกสารได้";
      //   } else {
      //     this.modal = Date.now();

      //     $(`#${this.myid}`).modal("show");
      //   }
      // } catch (error) {
      //   this.$MAIN.showErrorAlert(this, error);
      // }
    },
    download() {
      // try {
      //   if (this.can_action == 0) {
      //     throw "ไม่สามารถเข้าถึงสิทธิ์เอกสารได้";
      //   } else {
      axios({
        url: this.mydata.synology_path,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.mydata.tital);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
      //   }
      // } catch (error) {
      //   this.$MAIN.showErrorAlert(this, error);
      // }
    },
  },
};
</script>

<style scoped>
.img {
  width: 40px;
  height: 44px;
}
.img2 {
  width: 22px;
  height: 13.33px;
}
.tital {
  font-weight: 700;
  font-size: 0.75em;
  color: #0f172a;
}
.size {
  font-weight: 700;
  font-size: 0.75em;
  color: #94a3b8;
}
.date {
  color: #7e7e7e;
  font-weight: 500;
  font-size: 0.68em;
}

.bd {
  border-bottom: 1px solid #000000;
}
</style>