<template>
  <div
    class="modal fade"
    :id="modalId"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body m-0 p-0">
          <div class="row m-0 p-0">
         <img class="w-100" :src="dataimg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalImg",
  props: ["dataimg", "modalId"],
};
</script>

<style>
</style>