<template>
  <div class="row m-0 p-0 bg text-center">
    <div class="col-12 m-0 p-0">
      <div class="card h-100">
        <div class="card-body text-center" ref="printMe">
          <VueQrcode
            @click="qrsave()"
            class=""
            :options="{ width: 150 }"
            :value="item"
          ></VueQrcode>

          <!-- <VueQrcode value="Hello, World!"  class="w-100 "></VueQrcode> -->
        </div>

        <!-- ends: .card -->
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  name: "qrcode",
  props: ["item"],
  components: {
    VueQrcode,
  },

  methods: {
    async qrsave() {
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);

      //console.log(this.output);

      var link = document.createElement("a");
      link.href = this.output; //  link.href = uri;
      link.download = `qrUser.png`; // link.download = filename;
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style scoped>
.bg {
  height: 22vh;
}
.tital {
  font-weight: 600;
  font-size: 1.25em;
  color: #000000;
}
.subtital {
  font-weight: 600;
  font-size: 1.125em;
  color: #475569;
}
.subtital1 {
  font-weight: 400;
  font-size: 1.125em;
  color: #475569;
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside;
}
.overflow {
  height: 22vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.overflow ::-webkit-scrollbar {
  display: none;
}
</style>