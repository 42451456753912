<template>
  <div class="row m-0 p-0 bg myboder">
    <div class="col-12 m-0 p-0">
      <div class="card h-100">
        <div class="card-header py-0 px-sm-25 px-3">
          <p class="tital p-0 m-0">uploaded files</p>
        </div>
        <div class="card-body overflow">
          <!-- {{ item }} -->
          <div class="" v-for="(e, index) in item" :key="index">
            <BoxUploadedFile
              @sendjobStageNodeId="sendjobStageNodeId"
              :key="e.id"
              :keydata="e"
              :tital="e.doc_name"
              
              :status="e.uploaded_by == null ? 'N' : 'Y'"
            />
          </div>
          <div>
            <!-- {{ item[0].job_stage_node_id }} -->
            <BoxUploadedFileNew @click="reloamodal()" />
            <!-- ------------------------ modal ---------------------- -->
            <BoxBoxUploadedFileNew
              @sendjobStageNodeId="sendjobStageNodeId"
              :key="item.length > 0 ? item[0].job_stage_node_id : ''"
              :job_stage_node_id="
                item.length > 0 ? item[0].job_stage_node_id : $store.getters['jobdata/getjobStageNodeId']
              "
            />
          </div>
        </div>
        <!-- <div class="row m-0 p-0">
          <div class="col-12 p-0 m-0 d-flex justify-content-center">
            <div type="button" class="button2 mb-5 px-5 py-2">SAVE</div>
          </div>
        </div> -->
        <!-- ends: .card -->
      </div>
    </div>
  </div>
</template>

<script>
import BoxUploadedFile from "@/components/JobDetail/BoxUploadedFile.vue";
import BoxUploadedFileNew from "@/components/JobDetail/BoxUploadedFileNew.vue";
import BoxBoxUploadedFileNew from "@/components/JobDetail/BoxBoxUploadedFileNew.vue";
export default {
  name: "UploadedFile",
  props: ["item",'can_action'],
  components: { BoxUploadedFile, BoxUploadedFileNew, BoxBoxUploadedFileNew },
  data() {
    return {
      reload: "",
    };
  },
  methods: {
    reloamodal() {
      try {
        //console.log(this.keydata.can_action);
        if (!this.canAddDocument) {
          throw "ไม่สามารถเข้าถึงสิทธิ์การเพิ่มเอกสารได้";
        } else {
          $(`#BoxBoxUploadedFileNew`).modal("show");
        }
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    sendjobStageNodeId(value) {
      this.$emit("sendjobStageNodeId", value);
    },
  },
  computed: { 
    user: function () {
      return this.$store.getters["auth/getUser"];
    },
    canAddDocument: function(){ 
      return this.user.is_admin || (this.can_action == 'Y' && this.user.permissions.stage_document_add == 1)
    }
  }
};
</script>

<style scoped>
.tital {
  font-weight: 600;
  font-size: 1.25em;
  color: #000000;
}
.subtital {
  font-weight: 600;
  font-size: 1.125em;
  color: #475569;
}
.subtital1 {
  font-weight: 400;
  font-size: 1.125em;
  color: #475569;
}
.myboder {
  border: 1px solid #22b7a0;
  border-radius: 8px;
}
.button2 {
  background: #b8ede7;
  border: 1px solid #22b7a0;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.75em;
}
.overflow {
  height: 30vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.overflow ::-webkit-scrollbar {
  display: none;
}
</style>