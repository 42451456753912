<template>
  <div class="row m-0 p-0 bg">
    <div class="col-12 m-0 p-0">
      <div class="card h-100">
        <div class="card-header py-0 px-sm-25 px-3">
          <p class="tital p-0 m-0">Assigned jobs</p>
        </div>
        <div class="card-body overflow">
          <!-- ------------------------- -->
          <div class="m-0 p-0" v-if="item.length == 0">
            <p class="subtital">ไม่มีข้อมูลเอกสาร</p>
          </div>
          <!-- ------------------------- -->
          <div class="m-0 p-0" v-if="item.length > 0">
            <ul class="mb-5" v-for="(e, index) in item" :key="index">
              <li class="subtital">{{ e.doc_name }} ({{ e.doc_count }})</li>
              <li class="subtital1">{{ e.created_by }}</li>
            </ul>
          </div>
          <!-- ------------------------- -->
        </div>

        <!-- ends: .card -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Assigned",
  props: ["item"],
};
</script>

<style scoped>
.bg {
  height: 70vh;
}
.tital {
  font-weight: 600;
  font-size: 1.25em;
  color: #000000;
}
.subtital {
  font-weight: 600;
  font-size: 1.125em;
  color: #475569;
}
.subtital1 {
  font-weight: 400;
  font-size: 1.125em;
  color: #475569;
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside;
}
.overflow {
  height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.overflow ::-webkit-scrollbar {
  display: none;
}
</style>