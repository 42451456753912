<template>
  <div
    id="jobdetailpage "
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="card m-0 p-0">
      <!-- {{ cdata }} -->
      <div class="row m-0 p-0 w-100">
        <!-- ---------- Left ------------- -->
        <div class="col-12 col-md-12 col-lg-6 m-0 p-0">
          <!-- --------------- Header left ------------------ -->
          <div class="col-12 m-0 p-0">
            <div class="col-8 m-0 p-0">
              <LeftHeader
                :tital="`JOB ID : ` + cid"
                :subtital="projectdata.job_stage_name"
                line="2"
              />
            </div>
            <!-- {{ user }} -->
          </div>
          <!-- --------------- Body left ------------------ -->
          <div class="col-12 m-0 p-5 overflow">
            <!-- {{jobStageNodeId}}
            {{gendata}} -->
            <div
              class="m-0 p-0"
              v-for="(e, i) in $store.getters['jobdata/getgendata']"
              :key="i"
            >
              <!-- {{ e.data[0].job_stage_node_id }}
              {{ e.data[0].jsnid }} -->
              <TableJob
                :key="e"
                :item="e"
                :img="projectdata"
                :jobStageNodeId="$store.getters['jobdata/getjobStageNodeId']"
                @sendjobStageNodeId="sendjobStageNodeId"
                @sendcan_action="sendcan_action"
              />
            </div>
          </div>
        </div>
        <!-- ------------ Body Right ----------- -->
        <div class="col-12 col-md-12 col-lg-6 m-0 p-4">
          <div class="row m-0 p-0">
            <!-- ------------ Body Right1 ----------- -->
            <div class="col-6 mt-2">
              <Assigned
                :key="$store.getters['jobdata/getjobStageNodeId']"
                :item="itemAss"
              />
              <!-- {{dataqrcode}} -->
              <div class="mt-3"><Qrcode :item="dataqrcode" /></div>
            </div>
            <!-- ------------ Body Right2 ----------- -->
            <div class="col-6">
              <Button1 tital="APPROVE" @click="myapprove()" />
              <div class="mt-5">
                <!-- {{itemproject}} -->
                <ProjectFile
                  :key="$store.getters['jobdata/getjobStageNodeId']"
                  :item="itemproject"
                />
              </div>
              <div class="mt-3">
                <UploadedFile
                  @sendjobStageNodeId="sendjobStageNodeId"
                  :key="$store.getters['jobdata/getjobStageNodeId']"
                  :item="itemupload"
                  :can_action="canactive"
                />
                <!-- {{itemupload}} -->
              </div>
            </div>
          </div>
        </div>
        <!-- ----------------------- -->
      </div>
      <!-- --------------------------------body--------------------------- -->
    </div>
  </div>
</template>

<script>
import LeftHeader from "@/components/Common/LeftHeader.vue";
import RightHeaderSearch from "@/components/Common/RightHeaderSearch.vue";
import Button1 from "@/components/Common/Button1.vue";
import TableJob from "@/components/JobDetail/TableJob.vue";
import Assigned from "@/components/JobDetail/Assigned.vue";
import ProjectFile from "@/components/JobDetail/ProjectFile.vue";
import UploadedFile from "@/components/JobDetail/UploadedFile.vue";
import Qrcode from "@/components/JobDetail/qrcode.vue";
export default {
  name: "JobDetailPage",
  props: ["id", "mydata"],
  components: {
    LeftHeader,
    RightHeaderSearch,
    Button1,
    TableJob,
    Assigned,
    ProjectFile,
    UploadedFile,
    Qrcode,
  },
  data() {
    return {
      keepdata: [],
      dataqrcode: " ",
      approve: false,
      cid: this.$MAIN.Base64.decode(this.id),
      cdata: this.$MAIN.Base64.decode(this.mydata),
      canactive: "",
      projectdata: "",
      // gendata: "",
      gendata: [],
      jobStageNodeId: "",
      can_action: [],
      projectCreatedBy: "",
      item: [
        {
          STEPIMG: "/images/boo.png",
          STEPT: "เซ็นสัญญา แจ้งยอดชำระ",
          STEPST: "ล่าสุด 03-07-2564 เวลา 13.00น.",
          RESPONSIBLET: "Sales Contract",
          RESPONSIBLEST: "Sophia",
          STATUS: "Active",
        },
        {
          STEPIMG: "/images/boo.png",
          STEPT: "เซ็นสัญญา แจ้งยอดชำระ",
          STEPST: "ล่าสุด 03-07-2564 เวลา 13.00น.",
          RESPONSIBLET: "Sales Contract",
          RESPONSIBLEST: "Sophia",
          STATUS: "Wait",
        },
        {
          STEPIMG: "/images/boo.png",
          STEPT: "เซ็นสัญญา แจ้งยอดชำระ",
          STEPST: "ล่าสุด 03-07-2564 เวลา 13.00น.",
          RESPONSIBLET: "Sales Contract",
          RESPONSIBLEST: "Sophia",
          STATUS: "Wait",
        },
      ],
      itemAss: [
        // {
        //   tital: "upload สำเนาบัตรประชาชนลูกค้า",
        //   subtital: "nononononoonononononon",
        // },
        // {
        //   tital: "upload สำเนาบัตรประชาชนลูกค้า",
        //   subtital: "nononononoonononononon",
        // },
        // {
        //   tital: "upload สำเนาบัตรประชาชนลูกค้า",
        //   subtital: "nononononoonononononon",
        // },
      ],
      itemproject: [
        // {
        //   img: "/images/img1.png",
        //   tital: "หลักฐานการโอนเงิน.jpg",
        //   size: "300 kB",
        //   date: "ล่าสุด 2-05-2564 เวลา 13.00 น",
        // },
        // {
        //   img: "/images/img2.png",
        //   tital: "บิลค่าไฟ.jpg",
        //   size: "300 kB",
        //   date: "ล่าสุด 2-05-2564 เวลา 13.00 น",
        // },
      ],
      itemupload: [
        // {
        //   tital: "สำเนาบัตรประชาชนลูกค้า",
        //   status: "Y",
        // },
        // {
        //   tital: "สำเนาบัตรประชาชนลูกค้า",
        //   status: "N",
        // },
        // {
        //   tital: "โฉนดที่ดิน",
        //   status: "N",
        // },
      ],
    };
  },

  mounted() {
    //   console.log(this.user)
    this.convertdata();
  },
  methods: {
    async myapprove() {
      try {
        if (!this.canApproveProject) {
          throw "ไม่สามารถเข้าถึงสิทธิ์การอนุมัติได้";
        } else {
          //console.log(this.jobStageNodeId);
          let data = {
            jobStageNodeId: this.$store.getters["jobdata/getjobStageNodeId"],
          };
          let getAPI = await this.$API.call_API(
            "post",
            "workflow/ApproveStage",
            data,
            "auth"
          );
          this.$MAIN.showSuccessAlert(this, "APPROVE สำเร็จ");
          this.convertdata();
        }
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    sendcan_action(value) {
      //console.log(value);
      this.$store.dispatch("jobdata/actionc_getcanaction", value);
      this.canactive = this.$store.getters["jobdata/getcanaction"];
    },
    async GetStageByJobId(value) {
      try {
        let data = { jobid: value };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/GetStageByJobId",
          data,
          "auth"
        );
        // console.log(getAPI.data.data);
        this.dataqrcode = getAPI.data.data[0].qr_url;
        // console.log(getAPI.data.data[0].qr_url);
        let getdata = getAPI.data.data;
        this.projectCreatedBy = getdata[0].created_by;
        this.keepdata = getdata;
        let key = [];

        let groupdata = [];

        for (var i in getdata) {
          if (getdata[i].group_stage_name == null) {
            groupdata.push({ name: i, data: [] });
          } else {
            let getarray = groupdata.findIndex(
              (e) => e.name === getdata[i].group_stage_name
            );
            if (getarray == -1) {
              groupdata.push({ name: getdata[i].group_stage_name, data: [] });
            }
          }
        }

        for (var i in getdata) {
          if (getdata[i].group_stage_name == null) {
            let getarray = groupdata.findIndex((e) => e.name === i);

            groupdata[getarray].name = "";
            let datalength = groupdata[getarray].data.length;
            groupdata[getarray].data.push(getdata[i]);
            groupdata[getarray].data[datalength].jsnid =
              getdata[i].job_stage_node_id;
            //console.log(groupdata[getarray].data[datalength]);
          } else {
            let getarray = groupdata.findIndex(
              (e) => e.name === getdata[i].group_stage_name
            );
            //console.log(getarray);
            let datalength = groupdata[getarray].data.length;
            groupdata[getarray].data.push(getdata[i]);
            // console.log(getdata[i].job_stage_node_id);
            groupdata[getarray].data[datalength].jsnid =
              getdata[i].job_stage_node_id;
            //console.log(groupdata[getarray].data[datalength]);
          }
        }

        // console.log(groupdata);
        // this.gendata = groupdata;
        this.$store.dispatch("jobdata/actionc_getgendata", groupdata);

        this.loopdata(this.$store.getters["jobdata/getgendata"]);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    convertdata() {
      let convertdata = JSON.parse(this.cdata);
      //console.log(convertdata);
      this.projectdata = convertdata.img;

      let jobid = convertdata.jobid;
      this.GetStageByJobId(jobid);
      // this.gendata = convertdata.gendata;
      // console.log(convertdata);
      // let data = this.gendata;
      // for (var i in data) {
      //   // console.log(data[i].data);
      //   let loopdata = data[i].data;
      //   for (var j in loopdata) {
      //     //console.log(loopdata[j].can_action);
      //     if (loopdata[j].can_action == 1) {
      //       //console.log(loopdata[j].job_stage_node_id);
      //       this.can_action.push(loopdata[j].job_stage_node_id);
      //     }
      //   }
      // }
      // // console.log(this.can_action[0]);
      // // if(this.can_action.lenght){}
      // // console.log(this.can_action.length > 0);
      // if (this.can_action.length > 0) {
      //   this.GetDocumentByStage(this.can_action[0]);
      // }
    },
    loopdata(value) {
      let data = value;

      for (var i in data) {
        // console.log(data[i].data);
        let loopdata = data[i].data;
        for (var j in loopdata) {
          //console.log(loopdata[j].can_action);
          if (loopdata[j].can_action == "Y") {
            //console.log(loopdata[j].job_stage_node_id);
            this.can_action.push({
              jsnid: loopdata[j].jsnid,
              canaction: loopdata[j].can_action,
            });
          }
        }
      }
      // console.log(this.can_action[0]);
      // if(this.can_action.lenght){}
      // console.log(this.can_action.length > 0);
      // console.log(
      //   `this.keepdata[0].can_action :${this.keepdata[0].can_action}`
      // );
      if (this.can_action.length > 0) {
        this.$store.dispatch(
          "jobdata/actionc_jobStageNodeId",
          this.can_action[0].jsnid
        );
        this.GetDocumentByStage();
        this.$store.dispatch(
          "jobdata/actionc_getcanaction",
          this.can_action[0].canaction
        );
      } else {
        // console.log(this.keepdata[0]);
        let redata = [];
        redata.push(this.keepdata[0].jsnid);
        // console.log(redata);
        this.$store.dispatch("jobdata/actionc_jobStageNodeId", redata[0]);
        this.$store.dispatch(
          "jobdata/actionc_getcanaction",
          this.keepdata[0].can_action
        );
        this.GetDocumentByStage();
      }
    },
    async GetDocumentByStage() {
      try {
        // console.log(value);
        // let index = this.keepdata.findIndex(
        //   (e) =>
        //     (e.jsnid =
        //       this.$store.getters["jobdata/getjobStageNodeId"])
        // );
        //  console.log(index);
        //   console.log(this.keepdata[index].can_action);
        this.canactive = this.$store.getters["jobdata/getcanaction"];
        // console.log(this.canactive);
        let csendjobStageNodeId = this.can_action;
        let cres = csendjobStageNodeId.indexOf(
          this.$store.getters["jobdata/getjobStageNodeId"]
        );
        //console.log(cres);
        if (cres === -1) {
          this.approve = false;
        } else {
          this.approve = true;
        }
        //console.log(this.$store.getters["jobdata/getjobStageNodeId"]);

        // this.jobStageNodeId =
        //   this.$store.getters["jobdata/getjobStageNodeId"];
        // console.log(this.jobStageNodeId);
        let data = {
          jobStageNodeId: this.$store.getters["jobdata/getjobStageNodeId"],
        };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/GetDocumentByStage",
          data,
          "auth"
        );
        // console.log(getAPI.data.data);
        this.itemAss = getAPI.data.data;
        this.itemupload = getAPI.data.data;
        this.itemproject = [];
        this.loopitemproject(getAPI.data.data);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    loopitemproject(value) {
      for (var i in value) {
        if (value[i].synology_path !== null) {
          // console.log(value[i]);
          //console.log(value[i].synology_path);
          //   img: "/images/img1.png",
          //   tital: "หลักฐานการโอนเงิน.jpg",
          //   size: "300 kB",
          //   date: "ล่าสุด 2-05-2564 เวลา 13.00 น",
          let type = "";

          let typeimg = 0;
          // console.log(value[i].file_extention);
          if (
            String(value[i].file_extention).toLowerCase() == "png" ||
            String(value[i].file_extention).toLowerCase() == "gif" ||
            String(value[i].file_extention).toLowerCase() == "jpg" ||
            String(value[i].file_extention).toLowerCase() == "jpeg"
          ) {
            type = value[i].synology_path;
            typeimg = 1;
          } else {
            typeimg = 0;
            type = `/images/${String(
              value[i].file_extention
            ).toLowerCase()}.png`;
          }
          //console.log(type);
          this.itemproject.push({
            id: value[i].id,
            can_action: value[i].can_action,
            img: type,
            synology_path: value[i].synology_path,
            type: value[i].file_extention,
            typeimg: typeimg,
            tital: value[i].file_name,
            size: "",
            date: `ล่าสุด ${this.$DF(
              new Date(value[i].uploaded_at),
              "d-mm-yyyy"
            )} เวลา ${this.$DF(new Date(value[i].uploaded_at), "HH:MM")} น`,
          });
        }
      }
    },
    async sendjobStageNodeId(value) {
      // console.log(value);
      this.$store.dispatch("jobdata/actionc_jobStageNodeId", value);
      this.GetDocumentByStage();
      // console.log(this.$store.getters['jobdata/getgendata']);
    },
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/getUser"];
    },
    canApproveProject: function () {
      return (
        (this.user.is_admin == 1 && this.user.is_myself == 1) ||
        (this.user.is_admin == 1 &&
          this.user.is_myself == 0 &&
          this.projectCreatedBy == this.user.user_id) ||
        (this.canactive == "Y" && this.user.permissions.stage_approve == 1)
      );
    },
  },
};
</script>

<style scoped>
.overflow {
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.overflow ::-webkit-scrollbar {
  display: none;
}
</style>