<template>
  <div class="row mt-2 p-3 myboder">
    <div class="col-8 m-0 p-0 tital font-Roboto">{{ tital }}</div>
    <div class="col m-0 p-0">
      <div class="row m-0 p-0">
        <div type="button" v-if="status == 'Y'" class="col" v-html="done"></div>
        <div
          type="button"
          v-if="status == 'N'"
          class="col"
          v-html="upload"
          data-toggle="modal"
          data-target="#ModalUpload"
          @click="reloamodal()"
        ></div>
        <div
          type="button"
          class="col"
          v-html="bin"
          @click="DeleteDocumentByStage()"
        ></div>
      </div>
    </div>
    <!-- ------------------------ -->
    <ModalUpload
      @sendjobStageNodeId="sendjobStageNodeId"
      :key="ckeydata.id"
      :modalId="ckeydata.id"
      :keydata="ckeydata"
    />
    <!-- {{keydata.doc_name}} -->
    <!-- ------------------------ -->
    <!-- {{keydata}} -->
  </div>
</template>

<script>
import ModalUpload from "../../components/JobDetail/ModalUpload.vue";
export default {
  name: "BoxUploadedFile",
  components: { ModalUpload },
  props: ["keydata", "tital", "status"],
  data() {
    return {
      reload: "",
      ckeydata: {},
      bin: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4615 2.15315H10.9063C10.5194 2.15315 10.1769 1.90617 10.0549 1.54001L9.82802 0.858699C9.65643 0.344648 9.17753 0 8.6362 0H5.36306C4.82172 0 4.34287 0.344631 4.17128 0.8594L3.94442 1.54001C3.82237 1.90689 3.47989 2.15385 3.09291 2.15385H0.538462C0.241231 2.15385 0 2.39508 0 2.69231C0 2.98954 0.241231 3.23077 0.538462 3.23077H1.47034L2.02103 11.4872C2.11508 12.8965 3.29467 14 4.70759 14H9.29311C10.7053 14 11.8856 12.8958 11.9797 11.4872L12.5304 3.23077H13.4615C13.7588 3.23077 14 2.98954 14 2.69231C14 2.39508 13.7588 2.15315 13.4615 2.15315ZM5.1929 1.19901C5.21731 1.12578 5.28552 1.07622 5.36306 1.07622H8.6362C8.71374 1.07622 8.78194 1.12579 8.80635 1.1983L9.03321 1.87962C9.0648 1.97439 9.10429 2.06557 9.14881 2.15244H4.84975C4.89426 2.06485 4.93375 1.97441 4.96534 1.87892L5.1929 1.19901ZM10.9042 11.4147C10.8482 12.2604 10.1403 12.9224 9.29241 12.9224H4.70689C3.85971 12.9224 3.15106 12.2604 3.09506 11.4147L2.54945 3.23007H3.09291C3.16973 3.23007 3.24513 3.22072 3.32051 3.2121C3.35067 3.21713 3.37797 3.23007 3.40956 3.23007H10.589C10.6206 3.23007 10.6479 3.21713 10.678 3.2121C10.7534 3.22072 10.8281 3.23007 10.9056 3.23007H11.4491L10.9042 11.4147ZM8.97436 6.28135V9.87109C8.97436 10.1683 8.73313 10.4096 8.4359 10.4096C8.13867 10.4096 7.89744 10.1683 7.89744 9.87109V6.28135C7.89744 5.98412 8.13867 5.74289 8.4359 5.74289C8.73313 5.74289 8.97436 5.98412 8.97436 6.28135ZM6.10256 6.28135V9.87109C6.10256 10.1683 5.86133 10.4096 5.5641 10.4096C5.26687 10.4096 5.02564 10.1683 5.02564 9.87109V6.28135C5.02564 5.98412 5.26687 5.74289 5.5641 5.74289C5.86133 5.74289 6.10256 5.98412 6.10256 6.28135Z" fill="#E42C2C" fill-opacity="0.84"/>
</svg>`,
      done: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#20C997" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>`,
      upload: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#000000" class="bi bi-upload" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
</svg>`,
    };
  },
  mounted() {
    this.groupdata();
  },
  methods: {
    async DeleteDocumentByStage() {
      try {
        // console.log(this.keydata.id);
        // console.log(this.keydata.job_stage_node_id);
        // console.log(this.keydata.can_action);
        if (!this.canDeleteDocument) {
          throw "ไม่สามารถเข้าถึงสิทธิ์การลบเอกสารได้";
        } else {
          let data = {
            jobStageNodeId: this.keydata.job_stage_node_id,
            documentId: this.keydata.id,
          };
          let getAPI = await this.$API.call_API(
            "post",
            "workflow/DeleteDocumentByStage",
            data,
            "auth"
          );
          this.sendjobStageNodeId(this.keydata.job_stage_node_id);
        }
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    reloamodal() {
      try {
       // console.log(this.keydata.can_action);
        if (!this.canUploadDocument) {
          throw "ไม่สามารถเข้าถึงสิทธิ์การอัพโหลดเอกสารได้";
        } else {
          $(`#${this.ckeydata.id}`).modal("show");
        }
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    sendjobStageNodeId(value) {
      this.$emit("sendjobStageNodeId", value);
    },
    groupdata() {
      let gdata = this.keydata;
      //console.log(gdata);
      this.ckeydata = {
        id: gdata.id,
        job_stage_node_id: gdata.job_stage_node_id,
        doc_type: gdata.doc_type,
        prefix: gdata.prefix,
        doc_name: gdata.doc_name,
      };
    },
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/getUser"];
    },
    canDeleteDocument: function () {
      return this.user.is_admin || (this.keydata.can_action == 'Y' && this.user.permissions.stage_document_delete == 1)
    },
    canUploadDocument: function(){ 
      return this.user.is_admin || (this.keydata.can_action == 'Y' && this.user.permissions.stage_document_upload == 1)
    }
  }
};
</script>

<style scoped>
.tital {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
.myboder {
  border: 1px solid #22b7a0;
  border-radius: 8px;
}
</style>